<template>
  <div>
    <TabHeader :active="2" :chooseDate="query.date" :dateFlag="true" @dateChange="dateChange"></TabHeader>

    <div class="wine">
      <!-- 输入关键字查找报价 -->
      <div class="query">
        <div class="wx">
          <!-- <i class="iconfont icon-laba_bg wx_icon"></i> -->
          <img class="wx_icon" src="../../assets/icon/yin.png" alt="">
          <div ref="wxRolling2" class="wx_rolling" id="wxRolling2">
            {{ announcement }}
          </div>
        </div>
        <div class="find">
          <el-input
            ref="findInp"
            v-model="inputVal"
            placeholder="请输入关键字"
            clearable
            prefix-icon="el-icon-search"
            @keyup.native.enter="search"
          ></el-input>
          <div class="find_val" @click="search">搜索</div>
        </div>
        <!-- <div class="date">
          <el-date-picker
            v-model="query.date"
            type="date"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            :editable="false"
            :clearable="false"
            @change="getList"
          >
          </el-date-picker>
        </div> -->
      </div>
      <!-- tabs -->
      <div class="tabs">
        <div class="tabs_tit">
          <!-- 地区选择 -->
          <div class="tabs_name">
            <div
              class="tabs_name_item"
              :class="act == index ? 'active' : ''"
              v-for="(item, index) in tabsList"
              :key="item"
              @click="changeType(index)"
            >
              <i
                v-if="index == 0"
                class="iconfont icon-shoucang"
                style="font-size: 14px; color: #d83e26"
              ></i>
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <!-- 大分类tabs选择 -->
      <div
        class="tabs_foods"
        ref="tabs"
        :class="{ tabs_foods_more: moreFlag }"
        v-if="!searchFlag && act > 0"
      >
        <div
          class="tabs_item"
          :class="{ active: current == index }"
          v-for="(item, index) in tabsList2"
          :key="item.Name"
          @click="handClick(index)"
        >
          <div
            v-if="index == 17"
            style="width: 100px; height: 35px; text-align: left"
          >
            {{ item.Name }}
          </div>
          <span v-else>{{ item.Name }}</span>
        </div>
        <div class="more_list" @click="moreFlag = !moreFlag">
          {{ moreFlag ? '收起' : '更多' }}
          <i
            class="el-icon-caret-bottom more_list_icon"
            :class="{ more_list_icon_active: moreFlag }"
          ></i>
        </div>
      </div>

      <!-- 异动/自选报价 -->
      <div class="offer" v-if="current < 1 || act == 0">
        <div class="offer_item">
          <div
            class="offer_item_price"
            v-for="items in floatList"
            :key="items.Id"
            @click="goDetails(items.Title)"
          >
            <div class="item_name">{{ items.Title }}</div>
            <div class="item_price" :class="items.classColor">
              {{ items.value }}
            </div>
            <div class="item_float" :class="items.classColor">
              <span v-if="items.classColor === 'red'">↑</span>
              <span v-if="items.classColor === 'green'">↓</span>
              {{ Math.abs(items.changeValue1 - 0).toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
      <!-- 报价 -->
      <div class="offer" v-if="current >= 1 && act > 0">
        <div class="offer_title">
          <div style="flex: 3" class="text-left">名称</div>
          <div style="flex: 1">今日</div>
          <div style="flex: 1">涨跌</div>
        </div>

        <!-- v-for="(item, index) in dataList" :key="item.Name" -->
        <div class="offer_item" v-if="dataList.length > 0">
          <div
            class="offer_item_price"
            v-for="items in dataList[current - 1].foods"
            :key="items.Id"
            @click="goDetails(items.Title)"
          >
            <div class="item_name">{{ items.Title }}</div>
            <div class="item_price" :class="items.classColor">
              {{ items.value }}
            </div>
            <div class="item_float" :class="items.classColor">
              <span v-if="items.classColor === 'red'">↑</span>
              <span v-if="items.classColor === 'green'">↓</span>
              {{ Math.abs(items.changeValue1 - 0).toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <AllFooter ref="AllFooter"></AllFooter>
  </div>
</template>

<script>
export default {
  name: 'wine',
  data() {
    return {
      inputVal: '',
      announcementTimer: null,
      announcementTimer2: null,
      wxRolling2: null,
      scrollWidth: null,
      timeOut: null,
      announcementRolling: null,
      announcement: '',

      loading: null,

      tabsList: ['自选', '国酒行情', '洋酒行情'],

      query: {
        pageindex: 1,
        category1: '国酒',
        date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),
        type: 0,
        isLogin: true,
      },

      act: 1,
      current: 0,
      moreFlag: false,

      tabsList2: [],
      // 报价列表
      dataAllList: [],
      dataList: [],
      searchArr: [],
      searchFlag: false,

      // 异动数据列表
      floatAllList: [],
      floatList: [],
    }
  },

  created() {
    this.getList()

    this.getNoticeList()
  },

  methods: {
    // 切换地区
    async changeType(e) {
      if (e == 0) {
        await this.getMainList()
        this.act = e
        return
      }
      this.act = e
      this.query.category1 = e == 1 ? '国酒' : '洋酒'
      this.getList()
    },

    // tabs点击
    async handClick(e) {
      if (this.current == e) return
      if (e < 1) {
        this.getFloatList()
        this.current = e
      } else {
        this.current = e
      }
    },

    // 获取公告
    async getNoticeList() {
      const { data: res } = await this.$api.offer.getNoticeList({
        type: 2,
      })
      // console.log('获取公告', res[0].Content)
      this.announcement = res[0].Content

      this.$nextTick(() => {
        this.scrollWidth = this.$refs['wxRolling2'].scrollWidth
        this.timeOut = Math.round(this.scrollWidth / 60)
        this.wxRolling2 = document.getElementById('wxRolling2')
        this.wxRolling2.style.transform = 'translateX(0px)'

        // 定时器执行滚动公告
        this.announcementRolling = () => {
          console.log('执行', this.wxRolling2.style.transform)
          if (this.wxRolling2.style.transform != 'translateX(0px)') {
            this.wxRolling2.style.transform = 'translateX(740px)'
            this.wxRolling2.style.transition = 'none'
          }

          this.announcementTimer2 = setTimeout(() => {
            this.wxRolling2.style.transform = `translateX(${
              0 - this.scrollWidth
            }px)`
            this.wxRolling2.style.transition = `transform ${this.timeOut}s linear`
          }, 1000)

          return this.announcementRolling
        }
        
        // 设置位移
        clearInterval(this.announcementTimer)
        clearTimeout(this.announcementTimer2)
        
        this.announcementTimer = setInterval(
          this.announcementRolling(),
          (this.timeOut + 1) * 1000
        )
      })
    },

    goDetails(title) {
      this.$router.push({
        path: '/details',
        query: {
          title,
          type: 1
        }
      })
    },

    dateChange(date) {
      this.query.date = date
      this.getList()
    },

    // 获取报价列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)',
      })
      delete this.query.isChange
      this.query.date = this.$globalFun.parseTime(
        new Date(this.query.date),
        '{y}-{m}-{d}'
      )

      const { data: res } = await this.$api.index.getList(this.query)
      this.query.date = this.$globalFun.parseTime(
        new Date(res.date),
        '{y}-{m}-{d}'
      )

      // 加跌停颜色类名
      for (const arr of res.list) {
        for (const arrs of arr.foods) {
          let classColor = ''
          let value = 0
          let changeValue1 = 0

          classColor = this.riseAndFall(arrs.OfferValue, arrs.RetrieveValue)
          value = arrs.OfferValue
          changeValue1 = this.$globalFun.numFormat(
            arrs.OfferValue - arrs.RetrieveValue
          )

          arrs.classColor = classColor
          arrs.value = value
          arrs.changeValue1 = changeValue1 - 0
        }
      }
      // console.log('全部', res)

      const offerData = []
      for (const arr of res.list) {
        const arrs = arr.foods.filter((item) => {
          return item.OfferValue !== null && item.OfferValue !== 0
        })
        offerData.push({
          Name: arr.Name,
          foods: arrs,
        })
      }
      this.dataList = offerData

      this.dataAllList = this.dataList
      this.searchArr = this.dataList

      this.current = null
      this.tabsList2 = []

      const list = []
      for (const i of res.list) {
        list.push({
          Name: i.Name,
        })
      }

      this.tabsList2 = [
        {
          Name: '异动',
        },
        ...list,
      ]

      this.current = 1

      this.loading.close()
    },

    //获取异动数据列表
    async getFloatList() {
      this.query.isChange = 1
      const form = {
        ...this.query,
        isChange: 1,
      }
      const { data: res } = await this.$api.index.getList(form)

      const list = []
      for (const i of res.list) {
        list.push(...i.foods)
      }

      // 加跌停颜色类名
      for (const arr of list) {
        let classColor = ''
        let value = 0
        let changeValue1 = 0

        classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
        value = arr.OfferValue
        changeValue1 = this.$globalFun.numFormat(
          arr.OfferValue - arr.RetrieveValue
        )

        arr.classColor = classColor
        arr.value = value
        arr.changeValue1 = changeValue1 - 0
      }

      let testArr = []
      testArr = list.filter((item) => {
        return (
          item.OfferValue - 0 > 0 &&
          item.RetrieveValue - 0 > 0 &&
          item.OfferValue !== item.RetrieveValue
        )
      })

      this.floatList = testArr
      this.floatAllList = this.floatList
      // console.log('异动testArr', testArr, this.query.type)
    },

    // 获取自选列表
    async getMainList() {
      const { data: res } = await this.$api.offer.getMainList({
        date: this.query.date,
        type: 1,
      })
      // console.log(res)

      const list = res.list

      // 加跌停颜色类名
      for (const arr of list) {
        let classColor = ''
        let value = 0
        let changeValue1 = 0

        switch (this.query.type) {
          case 0:
            if (
              arr.OfferValue - 0 > 0 &&
              arr.RetrieveValue - 0 > 0 &&
              arr.OfferValue != arr.RetrieveValue
            ) {
              classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
              value = arr.OfferValue
              changeValue1 = this.$globalFun.numFormat(
                arr.OfferValue - arr.RetrieveValue
              )
            } else if (
              arr.ZSJValue - 0 > 0 &&
              arr.ZSJRetrieveValue - 0 > 0 &&
              arr.ZSJValue != arr.ZSJRetrieveValue
            ) {
              classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
              value = arr.ZSJValue
              changeValue1 = this.$globalFun.numFormat(
                arr.ZSJValue - arr.ZSJRetrieveValue
              )
            } else if (
              arr.SZValue - 0 > 0 &&
              arr.SZRetrieveValue - 0 > 0 &&
              arr.SZValue != arr.SZRetrieveValue
            ) {
              classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
              value = arr.SZValue
              changeValue1 = this.$globalFun.numFormat(
                arr.SZValue - arr.SZRetrieveValue
              )
            } else {
              classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
              value = arr.SZValue2
              changeValue1 = this.$globalFun.numFormat(
                arr.SZValue2 - arr.SZRetrieveValue2
              )
            }
            break

          case 1:
            classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
            value = arr.OfferValue
            changeValue1 = this.$globalFun.numFormat(
              arr.OfferValue - arr.RetrieveValue
            )
            break

          case 2:
            classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
            value = arr.ZSJValue
            changeValue1 = this.$globalFun.numFormat(
              arr.ZSJValue - arr.ZSJRetrieveValue
            )
            break

          case 3:
            classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
            value = arr.SZValue
            changeValue1 = this.$globalFun.numFormat(
              arr.SZValue - arr.SZRetrieveValue
            )
            break

          default:
            classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
            value = arr.SZValue2
            changeValue1 = this.$globalFun.numFormat(
              arr.SZValue2 - arr.SZRetrieveValue2
            )
            break
        }

        arr.classColor = classColor
        arr.value = value
        arr.changeValue1 = changeValue1 - 0
      }

      this.floatList = list
      // console.log('22222', list, this.floatList)
    },

    // 搜索
    async search() {
      // console.log(this.inputVal)
      if (this.inputVal.trim() == '') {
        this.dataList = this.searchArr
        this.searchFlag = false
        return
      }

      this.inputVal = this.inputVal.toUpperCase()

      const searchArr = []
      const dataList = this.searchArr

      dataList.forEach((item) => {
        const arr = item.foods.filter((items) => {
          let key = 1
          let codeKey = 1
          let keywords = items.Title
          let codeKeywords = items.FactoryCode || ''
          for (const i of this.inputVal.trim()) {
            if (keywords.indexOf(i) < 0) {
              key = 0
              break
            } else {
              keywords = keywords.replace(i, '')
            }
          }

          for (const i of this.inputVal.trim()) {
            if (codeKeywords.indexOf(i) < 0) {
              codeKey = 0
              break
            } else {
              codeKeywords = codeKeywords.replace(i, '')
            }
          }
          return key || codeKey
        })
        // console.log(arr)
        if (arr.length > 0) {
          searchArr.push({
            Name: item.Name,
            foods: arr,
          })
        }
      })

      // console.log("searchArr",searchArr)

      if (searchArr.length < 1) {
        return this.$message('查找不到，换一个试试吧')
      }

      this.dataList = searchArr
      this.searchFlag = true
    },

    // 判断涨跌
    riseAndFall(newV, oldV) {
      const num = newV - oldV

      if (num > 0) {
        return 'red'
      } else if (num === 0) {
        return ''
      } else {
        return 'green'
      }
    },
  },

  mounted() {},

  activated() {
    this.$nextTick(() => {
      if (!this.timeOut) return
      clearInterval(this.announcementTimer)
      clearTimeout(this.announcementTimer2)
      this.announcementTimer = setInterval(
        this.announcementRolling(),
        (this.timeOut + 1) * 1000
      )
    })
  },

  deactivated() {
    clearInterval(this.announcementTimer)
    clearTimeout(this.announcementTimer2)
    this.wxRolling2.style.transform = 'translateX(0px)'
    this.wxRolling2.style.transition = 'none'
  },
}
</script>

<style scoped lang="scss">
.wine {
  width: 1126px;
  margin: 0 auto;

  .query {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .wx {
      width: 740px;
      height: 40px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2280ff;
      overflow: hidden;
      display: flex;
      align-items: center;

      .wx_icon {
        background: #fff;
        width: 18px;
        z-index: 9;
      }

      .wx_rolling {
        margin-left: 6px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
      }
    }

    .find {
      flex: 1;
      height: 40px;
      margin-left: 35px;
      background: rgba(245, 245, 245, 0.39);
      border-radius: 10px;
      position: relative;

      .find_val {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d83e26;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 9;
      }
    }

    .date {
      width: 220px;
    }
  }

  .tabs {
    width: 100%;
    // height: 178px;
    // background: #ffffec;
    // border: 1px solid #f6731b;

    .tabs_tit {
      width: 1126px;
      height: 53px;
      background: rgba(248, 248, 248, 1);
      border-radius: 5px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tabs_name {
        display: flex;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 22px;
        color: #5d5d5d;

        .tabs_name_item {
          // width: 100px;
          margin-left: 26px;
          text-align: left;
          cursor: pointer;
        }
      }

      .tabs_sort {
        width: 100px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: rgba(216, 62, 38, 1);
        border: 2px solid #ffffff;
        border-radius: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
      }

      .tabs_screening {
        height: 32px;
        line-height: 32px;
        margin-left: 12px;
        margin-right: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #000000;
        cursor: pointer;
      }
    }

    .screening {
      width: 1100px;
      padding: 4px 16px 20px 10px;
      margin: 10px auto 0;
      background: rgba(248, 248, 248, 1);
      border-radius: 5px;

      .screening_item {
        padding: 3px 0 0;
        display: flex;

        .screening_title {
          width: 48px;
          height: 27px;
          margin-top: 9px;
          background: rgba(127, 127, 140, 1);
          border-radius: 17px 0px 17px 17px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          line-height: 27px;
          color: #ffffff;
          text-align: center;
        }

        .screening_select {
          margin-left: 12px;
          padding-bottom: 12px;
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #eeeeee;

          .screening_select_item {
            margin-top: 9px;
            padding: 0 9px;
            height: 27px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 27px;
            color: #4f4f4f;
            margin-left: 6px;
            cursor: pointer;
          }

          .screening_select_item_active {
            height: 27px;
            background: rgba(255, 234, 230, 1);
            border-radius: 14px;
            font-weight: 600;
            color: #d83e26;
            text-align: center;
          }
        }

        .screening_select:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .tabs_foods {
    display: flex;
    flex-wrap: wrap;
    width: 1116px;
    height: 35px;
    padding: 8px 10px 8px 0;
    margin-top: 10px;
    overflow: hidden;
    background: rgba(248, 248, 248, 1);
    border-radius: 5px;
    position: sticky;
    top: 0;

    .tabs_item {
      margin-left: 14px;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000;
      cursor: pointer;
    }

    .more_list {
      width: 68px;
      height: 29px;
      border: 2px solid #e5e5e5;
      background: #f8f8f8;
      border-radius: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #919191;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 8px;
      z-index: 2;

      .more_list_icon {
        transition: all 0.3s;
      }

      .more_list_icon_active {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  .tabs_foods_more {
    height: auto;
  }

  .offer {
    width: calc(100% - 20px);
    padding: 0 10px;
    // background: #f3f3f3;
    background: url('../../assets/img/c3j4.jpg') 20% #f3f3f3;
    margin-top: 10px;

    .offer_title {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      color: #818181;
    }

    .offer_item {
      width: 100%;

      .offer_item_price {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c8c8c8;
        color: #353535;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;

        .item_name {
          flex: 3;
          text-align: left;
        }

        .item_price {
          flex: 1;
        }

        .item_float {
          flex: 1;
        }
      }
    }
  }

  .active {
    color: #d83e26 !important;
  }

  .red {
    color: red !important;
  }

  .green {
    color: #00962a !important;
  }
}
</style>
